import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import ContactForm from '../../components/ContactForm'
import { graphql } from 'gatsby'
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <div className="columns">
                <div className="column has-text-centered is-size-4">
                  <h4>Address</h4>
                  Hamilton Mountain, Ontario<br/>
                  Location to be disclosed upon contact<br/>
                  <a href="tel:289-440-6762">289-440-6762</a><br/>
                  <a href="https://www.greenwillowselfhypnosis.com">www.greenwillowselfhypnosis.com</a>
                  <PreviewCompatibleImage imageInfo={this.props.data.file}/>
                </div>
                <div className="column">
                  <iframe title="GoogleMap"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2908.27487057634!2d-79.88486848451674!3d43.2037237791393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c9a8dd58698b3%3A0x4bb6a9937bb36404!2sTrieste%20Pl%2C%20Hamilton%2C%20ON%2C%20Canada!5e0!3m2!1sen!2sau!4v1577614130418!5m2!1sen!2sau"
                      width="400" height="500" frameBorder="0" allowFullScreen=""/>
                </div>
                <div className="column">
                  <ContactForm type="main"/>
                </div>
              </div>

            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    file(relativePath: { eq: "headset.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 526, quality: 92) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`